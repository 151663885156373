import { createRouter, createWebHistory } from 'vue-router';

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
    },

    {
      path: '/tickets-history',
      name: 'ticketsHistory',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/TicketsHistory'),
    },
    {
      path: '/backoffice-tickets-list',
      name: 'backofficeTicketsView',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/BackofficeTicketsView'),
    },
    {
      path: '/terminal-results',
      name: 'terminalResults',
      component: () => import(/* webpackChunkName: "home" */ '@/components/Results'),
    },
    {
      path: '/ticket-details',
      name: 'ticketDetails',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/TicketDetails'),
    },
    {
      path: '/backoffice-ticket-details',
      name: 'backofficeTicketDetails',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/BackofficeTicketDetails'),
    },
    { path: '/:pathMatch(.*)*', redirect: '/' }
  ],
});
