/**
 * Search for query params of game/product Id
 *
 * @returns {object}
 */
function getParams() {
  const url = window.location.href;
  const index = url.indexOf('?');
  const paramsObj = {};
  if (index >= 0) {
    const params = url.split('?')[1].split('&');
    let i = 0;
    while (i < params.length) {
      const splited = params[i].split('=');
      const [first, second] = splited;
      paramsObj[first] = second;
      i += 1;
    }
  }
  window.lottery = {
    debugMode: paramsObj.debugMode === 'true',
  };
  return paramsObj;
}

export default getParams;
