export default {
  queryParametars: {},
  config: null,
  gatewayConfigurationDetails: null,
  theme: 'dark',
  palette: 'default',
  isGateway: 'default',
  isVideoUnavailable: true,
  screenSize: null,
  gamesInfoActive: false,
  currentEvent: '',
  isAudioOn: false,
  offer: null,
  activeEventId: 0,
  isMinimizeVisible: false,
  isBetslipOpen: true,
  oddType: 1,
  timezone: null,
  user: null,
  userBalance: null,
  loader: true,
  isVisualizationReady: false,
  infoModalActive: false,
  isAndroid: false,
  activeTicketHistory: false,
  activeHistoryLoader: false,
  ticketsHistory: [],
  internetConnection: true,
  betslipBlockers: null,
  terminalUser: null,
  isIosApp: null,
  showCursor: true,
  sevenData: null,
  rgsIntegration: false,
  userPusherSubscribed: false,
};
