import axios from 'axios';

/*
BaseURL  for VSTS
*/
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
})
/*
uRL for offer VSGS
*/
const httpGameService = axios.create({
  baseURL: process.env.VUE_APP_API_GAME_SERVICE,
});
const sevenHttp = axios.create({
  baseURL: process.env.VUE_APP_SEVEN_PLATFORM_API,
});

export {
  http,
  httpGameService,
  sevenHttp,
}
