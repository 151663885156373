const createUserFromRsg = (data) => {
  const {
    Uuid, email, firstName, lastName, timezone, token, balance,
  } = data;
  return {
    settings: { oddsType: 1 },
    id: Uuid,
    token,
    email,
    firstName,
    lastName,
    timezone,
    balance,
    type: 'ANONYMOUS',
    profile: {
      token,
      id: Uuid,
    }
  };
};

export default createUserFromRsg;
