import { createStore } from 'vuex';
import assign from 'lodash/assign';

import SdkStoreConfig from '@nsftx/games-sdk-js/src/store/storeConfig';
import storeConfig from './storeConfig';


export default createStore(assign(storeConfig, {
  modules: SdkStoreConfig.modules,
}));
