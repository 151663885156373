import {http, httpGameService} from './http';

const getData = (url, headers) => http.get(url, { headers });
const postData = (url, data, headers) => http.post(url, data, { headers });
const getGameServiceData = (url, headers) => httpGameService.get(url, { headers });

export {
  getData,
  postData,
  getGameServiceData,
};
