import actionsApp from './actions';
import actionsGames from '@nsftx/games-sdk-js/src/store/actions';
import gettersApp from './getters';
import gettersGames from '@nsftx/games-sdk-js/src/store/getters';
import mutationsApp from './mutations';
import mutationsGames from '@nsftx/games-sdk-js/src/store/mutations';
import stateApp from './state';
import stateGames from '@nsftx/games-sdk-js/src/store/state';
import modules from './modules';
import plugins from './plugins';
import {assign} from 'lodash';

const actions = assign(actionsGames, actionsApp);
const getters = assign(gettersGames, gettersApp);
const mutations = assign(mutationsGames, mutationsApp);
const state = assign(stateGames, stateApp);

export default {
  state,
  getters,
  actions,
  mutations,
  plugins,
  modules,
};
