import { BusService } from '@nsftx/games-bus';
import has from 'lodash/has';

const busService = async (config, store) => {
  const { pathname } = window.location;
  const isGame = pathname === '/' || store.getters.iosApp;
  let gamesBusService;
  try {
    const adapters = [];
    if (isGame) {
      const pusherKey = config.productName === 'VirtualSoccerDev'
        ? '9026609befc0809e6234' : process.env.VUE_APP_PUSHER_KEY;
      adapters.push({ name: 'PusherAdapter', options: { key: pusherKey } });
      // adapters.push({ name: 'LocalAdapter', options: { initialChannels: ['assetsMgmt', 'localAppConfigChannel', 'connectionChecker'] } });
    }
    gamesBusService = await new BusService({
      environment: config.environment,
      platform: config.platform,
      productName: config.productName,
      productId: config.productId,
      tenantId: config.tenantId,
      locale: config.locale,
      applicationName: config.applicationName,
      useCommonEventName: true,
      adapters,
      clientMessageProtocol: config.messageProtocol,
    });

    gamesBusService.addChannel('Product', config.productInstanceUuid);
    // Product.Jackpot only transmits 'updateJackpot' messages
    if(!store.getters.layoutTerminal) gamesBusService.addChannel('Product.Jackpot', config.productInstanceUuid);
    gamesBusService.start();
    gamesBusService.sendMessage({
      action: 'Slave.Loaded',
      enforceEvent: true,
    }, true);
    if (store.getters.isAndroid && has(window, 'WebAppListener')) {
      // eslint-disable-next-line no-undef
      WebAppListener.sendMessage('Slave.Loaded');
    }
  } catch (error) {
    console.log('error bus service', error);
  }
  return gamesBusService;
};

export default busService;
