<template>
  <div
    v-if="detailsMode === 'standalone'"
    class="left-wrapper"
  >
    <div class="left">
      <div class="date-range">
        {{ store.getters.translation('general_date_range') }}
      </div>
      <DatePicker
        v-model="range"
        :masks="masks"
        :locale="locale"
        is-range
        class="date-picker-component"
        :popover="popover"
        @change="updated"
      >
        <template #default="{ inputValue, inputEvents }">
          <div class="date-picker-wrapper">
            <div class="date-picker">
              <Input
                class="date-picker-input left"
                input-type="text"
                :value="inputValue.start"
                :should-update-value-on-blur="false"
                :should-select-text="false"
                :readonly="true"
                v-on="inputEvents.start"
              />
              <span class="dash">{{ '-' }}</span>
              <Input
                class="date-picker-input right"
                input-type="text"
                :value="inputValue.end"
                :should-update-value-on-blur="false"
                :should-select-text="false"
                :readonly="true"
                v-on="inputEvents.end"
              />
            </div>
          </div>
        </template>
      </DatePicker>
    </div>
    <i class="icon icon-calendar" />
  </div>
</template>
<script setup>
import { DatePicker } from 'v-calendar';
import Input from '@nsftx/games-sdk-js/src/components/Input';
import {useStore} from 'vuex';
import {defineProps, toRefs} from '@vue/compat';
import {defineEmits, onBeforeMount, ref, watch} from 'vue';

const store = useStore();
const props = defineProps({
  dateFrom: {
    type: Date,
    default: () => new Date(),
  },
  dateTo: {
    type: Date,
    default: () => new Date(),
  },
  detailsMode: {
    type: String,
    default: 'standalone',
  },
  locale: {
    type: String,
    default: 'en',
  },
});
const emit = defineEmits(['dateRange']);
const {
  detailsMode,
  locale,
  dateFrom,
  dateTo,
} = toRefs(props);
const masks = {
  input: 'DD.MM.YYYY',
};
const range = ref({
  start: dateFrom,
    end: dateTo,
});
const popover = {
  visibility: 'focus',
};
onBeforeMount(() => {
  emit('dateRange', range.value);
});
watch(range, async () => {
  emit('dateRange', range.value);
})
</script>

<style lang="scss">
.left-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--text-primary-3);
  margin-bottom: 16px;
  width: 60%;
  float: left;
  padding-bottom: 2px;
  position: relative;

  .icon-calendar {
    color: var(--text-primary-1);
    font-size: 18px;
    opacity: 0.6;
    position: absolute;
    right: 0;
  }

  .left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .date-picker-component {
      width: 100%;
    }

    .date-range {
      font-size: 12px;
      color: var(--text-primary-1);
      opacity: 0.6;
    }

    .date-picker-wrapper {
      .date-picker {
        color: var(--text-primary-1);
        display: flex;
        flex-direction: row;
        align-items: center;

        * {
          user-select: none;
        }

        .input-container {
          width: 70px;
        }

        .date-picker-input {
          outline: none;
          border: none;
          background-color: var(--background-1);
          color: var(--text-primary-1);
          font-size: 14px;
          height: auto;

          &.left {
            min-width: 72px;
            background-color: transparent;
          }
          &.right {
            width: 100%;
            z-index: 1;
            background-color: transparent;
          }

          input {
            width: 100%;
            height: 18px;
            padding: 0;
            background-color: var(--background-1);
          }
        }

        .dash {
          margin-left: 2px;
          margin-right: 2px;
        }

        &:last-child {
          width: 100%;
          z-index: 100;
          background-color: transparent;

          .input {
            background-color: transparent;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.left-wrapper .left .vc-container .vc-title {
  text-transform: capitalize;
}
.left-wrapper .left .vc-container .vc-weekday {
  text-transform: uppercase;
}

@media (max-width: 660px) {
  .right-wrapper {
    width: 100%;
  }
}
</style>
