import {
  forEach,
  merge,
} from 'lodash';

const createQueryParams = (data) => {
  let queryParams = '';
  forEach(data, (prop, key) => {
    queryParams += queryParams.length ? '&' : '?';
    queryParams += `${key}=${prop}`;
  });
  return queryParams;
};

const createHeaders = (data, newHeaders = null, removeHeader = null) => {
  let headers = {
    'Content-Type': 'application/json',
    'SEVEN-LOCALE': data.language,
    'X-Nsft-Seven-Tenant-Uuid': data.uuid,
  };
  if (removeHeader) {
    delete headers[removeHeader];
  }
  if (newHeaders) {
    headers = merge(headers, newHeaders);
  }
  return headers;
};

export {
  createQueryParams,
  createHeaders,
};
