import store from '@/store';
const formatPrintTicketBets = (bets) => bets.map((bet) => {
  // eslint-disable-next-line no-param-reassign
  bet.outcomeType = formatOutcomeName(bet); // translate outcome type into outcome name
  return bet;
});
const formatOutcomeName = (bet) => `${outcomeNameTranslation(bet.outcomeName)}`;
const formatTicketBets = (ticket) => {
  return ticket.bets.map((bet) => {

    return {
      id: bet.eventId,
      status: ticket.bets.length === 1 ? formatStatus(bet.status) : translateStatus(bet.status),
      round: bet.eventId,
      market: formatOutcomeName(bet),
      outcome: bet.eventName,
      system: null,
      stake: bet.stake,
      odd: bet.outcomeOdd,
      winnings: bet.winnings,
      time: bet.eventStartDateTime,
    };
  });
};
const translateStatus = (status) => {
  // eslint-disable-next-line no-param-reassign
  status.name = store.getters.translations[`general_ticket_status_${status.name.toLowerCase()}`];
  return status;
};
const formatStatus = (status) => {
  if (store.getters.isTerminal) {
    if (status.value === 'PAIDOUT') {
      // eslint-disable-next-line no-param-reassign
      status.value = "PAYEDOUT";
      // eslint-disable-next-line no-param-reassign
      status.name = store.getters.translations.general_payedout;
    } else {
      // eslint-disable-next-line no-param-reassign
      status.name = store.getters.translations[`general_ticket_status_${status.name.toLowerCase()}`];
    }
  } else {
    if (status.value === 'WON' || status.value === 'PAIDOUT') {
      // eslint-disable-next-line no-param-reassign
      status.value = "PAYEDOUT";
      // eslint-disable-next-line no-param-reassign
      status.name = store.getters.translations.general_payedout;
    } else {
      // eslint-disable-next-line no-param-reassign
      status.name = store.getters.translations[`general_ticket_status_${status.name.toLowerCase()}`];
    }
  }
  return status;
};
const outcomeNameTranslation = (text) => {
  const patternWithoutNumber = /\b\d+(?::\d+|\.\d+)?\b/g; // Regex pattern to capture text before and after the numbers
  const patternNumber = /\b\d+(?::\d+|\.\d+)?\b/g; // Regex pattern to capture text before and after the numbers
  const outcomeWithoutNumbers = text.replace(patternWithoutNumber, '').trim();
  const outcomeNumbers = text.match(patternNumber);
  const addUnderline = `general_${outcomeWithoutNumbers.toLowerCase().replace(/\s+/g, '_')}`;

  return `${store.getters.translation(addUnderline)}${outcomeNumbers ? ` ${outcomeNumbers}` : ''}`;
};
const ticketFormater = (ticket) => {
  const bets = formatTicketBets(ticket);

  if (ticket.superBonus) {
    // eslint-disable-next-line no-param-reassign
    ticket.superBonus.name = store.getters.translation(ticket.superBonus.name);
  }

  return {
    id: ticket.id,
    payout: ticket.payout,
    winnings: ticket.winnings,
    payin: ticket.payin,
    payinTax: ticket.payinTaxAmount,
    payoutTax: ticket.payoutTax,
    createdAt: ticket.createDateTime,
    status: formatStatus(ticket.status),
    maxPossibleWin: ticket.maxPossibleWinnings,
    totalOdds: ticket.ticketType === "COMBO" ? ticket.totalOdd : null,
    ticketType: ticket.ticketType,
    superBonus: ticket.superBonus,
    bets,
  };
}
export {
  ticketFormater,
  formatPrintTicketBets,
}
