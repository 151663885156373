<template>
  <div
    class="ticket-history-wrapper"
  >
    <Modal
      v-if="activeTicketHistory"
      ref="modal"
      :full-screen="isMobile || isTablet"
      :show-footer="true"
      class="flex"
      :width="isMobile || isTablet ? 'calc(100% - 48px)' : '832px'"
      :height="isDesktop ? '500px' : ''"
      @close-modal-outside="handleCloseModal"
      @close-modal="handleCloseModal"
    >
      <template #header>
        <div class="flex-center">
          <span class="product-name">{{ store.getters.translation('general_virtualSoccer') }}</span>
          <i
            class="icon icon-close-a"
            @click="handleCloseModal"
          />
        </div>
      </template>
      <template #body>
        <TicketsHistoryWrapper />
      </template>
    </Modal>
  </div>
</template>
<script setup>
import Modal from '@nsftx/games-sdk-js/src/components/Modal';
import TicketsHistoryWrapper from './TicketsHistoryWrapper';
import {useStore} from 'vuex';
import {computed} from 'vue';

const store = useStore();
const activeTicketHistory = computed(() => store.getters.activeTicketHistory);
const isMobile = computed(() => store.getters.isMobile);
const isTablet = computed(() => store.getters.isTablet);
const isDesktop = computed(() => store.getters.isDesktop);

/**
 * handling of ticket history must be included inside SDK
 * ticket history need to be same for all games
 * only difference will be, but maybe, inside bet of ticket
 */
const handleCloseModal = (() => {
  store.dispatch('toggleTicketHistory', false);
})
</script>

<style lang="scss" scoped>
@import 'v-calendar/dist/style.css';
@import './styles/helpers/datePicker';
@import './styles/helpers/_scrollable.scss';
.ticket-history-wrapper {
  position: relative;
  :deep(.modal-back) {
    .modal {
      background-color: var(--background-1);
      padding-bottom: 1em;
      height: 500px;

      .modal-header,
      .modal-body {
        color: var(--text-primary-1);
      }

      .modal-header {
        font-size: 16px;
      }
      .modal-body {
        font-size: 14px;
      }

      .input-container {
        height: 18px;
      }

      .ticket-details-modal {
        .modal {
          background-color: var(--card);
          height: auto;
        }
      }

      .tickets-list-wrapper {
        overflow-y: auto;
        height: calc(100% - 112px);
        padding-bottom: 48px;
        scrollbar-width: none !important; // for firefox
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
    }
  }

  .flex-center {
    display: block;
    align-items: center;
    text-align: center;

    .icon {
      float: right;
      cursor: pointer;
    }

    .product-name {
      font-size: 24px;
      text-align: center;
    }
  }
}

@media (max-width: 420px) {
  :deep(.tickets-list-wrapper) {
    max-height: 100%;
  }
}

@media (max-width: 660px) {
  .ticket-history-wrapper {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product-name {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  :deep(.modal-back) {
    .modal {
      .modal-body {
        .tickets-history {
          .tickets-list-wrapper {
            max-height: 100%;
            padding-bottom: 100px;
          }
        }
      }
    }
  }
}
</style>
