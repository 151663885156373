import { sevenHttp } from './http';

const getHeaders = (data) => ({
  'HTTP-X-NAB-DP': data.application,
  'HTTP-X-SEVEN-CLUB-UUID': data.tenantId,
  'SEVEN-LOCALE': data.locale || 'en',
});

const authenticateUser = async (data) => {
  const userData = {
    authStrategy: 'token',
    id: data.playerId,
    token: data.token,
  };
  const headers = {
    authorization: `Bearer ${data.token}`,
    'SEVEN-TP-TOKEN': data.token,
    ...getHeaders(data),
  };
  const response = await sevenHttp.post(process.env.VUE_APP_AUTHENTICATE,
    userData, { headers });
  return response;
};

const loginCheck = async (data, token) => {
  const headers = {
    authorization: `Bearer ${token}`,
    'SEVEN-TP-TOKEN': data.token,
    ...getHeaders(data),
  };
  const response = await sevenHttp.get(process.env.VUE_APP_JWT_LOGIN_CHECK, { headers });
  return response;
};

const getBalance = async (data, token) => {
  const headers = {
    authorization: `Bearer ${token}`,
    'X-NSFT-WALLET-USERGROUP': data.tenantId,
    ...getHeaders(data),
  };
  const response = await sevenHttp.get(process.env.VUE_APP_BALANCE, { headers });
  return response.status === 200 ? response.data.balance : 0;
};

export {
  authenticateUser,
  loginCheck,
  getBalance,
};
